"use client";
import { toast } from "@/components/ui/use-toast";
import useCartStore, { ProductInterface } from "@/stores/useCartStore";

import { useRouter } from "next/navigation";

interface PlansProps {
  products: ProductInterface[];
}

export default function BlackHome({ products }: PlansProps) {
  const router = useRouter();
  const { addToCart, cart } = useCartStore();

  const selectPlan = (product: ProductInterface) => {
    // Remove any existing subscription products from the cart
    const existingSubscription = cart.find(
      (item: any) => item.type === "subscription"
    );
    if (existingSubscription) {
      useCartStore.getState().removeFromCart(existingSubscription.id);
    }

    // Add the selected plan to the cart
    addToCart(product, 1);

    // Show toast notification
    toast({
      title: "Plan añadido al carrito",
      description: `Has seleccionado el plan ${product.name}.`,
      duration: 3000,
    });

    // Navigate to payment page
    router.push("/payment");
  };
  return (
    <>
      <div
        onClick={() => selectPlan(products[0])}
        className="hidden md:block w-full h-[70vh] xl:h-[100vh] h-max-screen bg-[url('https://maluapp.b-cdn.net/resources/Mesa%20de%20trabajo%201%20(1).png')] bg-no-repeat bg-cover bg-center cursor-pointer"
      ></div>
      <div
        onClick={() => selectPlan(products[0])}
        className=" md:hidden w-full h-[90vh] h-max-screen bg-[url('https://maluapp.b-cdn.net/resources/Mesa%20de%20trabajo%202.png')] bg-no-repeat bg-cover bg-top cursor-pointer"
      ></div>
    </>
  );
}
